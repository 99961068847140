// --------------------------------------------------------------------------
// video.js
// --------------------------------------------------------------------------
// This file adds functionality for loading banner video
// --------------------------------------------------------------------------

RN.video = function() {

    'use strict';

// --------------------------------------------------------------------------
// Start loading the video
// --------------------------------------------------------------------------
    function init() {
        var video = $('video');
        if(video.length > 0 && !Modernizr.touchevents) {
            video.attr('src', video.data('src'));
            checkState(video);
        }
    }

// --------------------------------------------------------------------------
// Check for video ready state
// --------------------------------------------------------------------------
    function checkState(video) {
        if(video[0].readyState == 4) {
            video[0].currentTime = 0;
            video[0].play();
            video.addClass('banner__media--loaded');
        } else {
            setTimeout(function() {   
                checkState(video);   
            },50);
        }
    }


// --------------------------------------------------------------------------
// Return public methods
// --------------------------------------------------------------------------

    return {
        init: init
    };

}();