// --------------------------------------------------------------------------
// application.js
// --------------------------------------------------------------------------
// This file imports and initialises modules.
//
// Add module names to the modules array for automatic initialisation.
//
// No specific javascript should be placed in this file.
// --------------------------------------------------------------------------

$(function() {

    'use strict';

    var modules = [
        'siteSearch', 
        'languageSelect', 
        'toggleNavigation', 
        'backToTop', 
        'selects', 
        'sliders', 
        'tweets', 
        'toggleFilters', 
        'multipleTables', 
        'accordionTable',
        'modal',
        'password',
        'video',
        'responsiveTable',
        'imageCaptions'];

    for(var i = 0; i < modules.length; i++) {
        RN[modules[i]].init();
    }

    byBehaviour('sticky-element').sticky();

    if($('.category-navigation').length > 0) {
        RN.sectionNavigation.init();
    }

    $('form').parsley();

});