// --------------------------------------------------------------------------
// multipleTables.js
// --------------------------------------------------------------------------
// This file adds functionality to convert a table into multiple tables
// with a drop down select.
// --------------------------------------------------------------------------

RN.multipleTables = function() {

    'use strict';

// --------------------------------------------------------------------------
// Initialisation
// --------------------------------------------------------------------------
    function init() {
        byBehaviour('multiple-tables').each(function(i) {
            var table = $(this).find('table');
            if(table.find('tr:first-child').find('td').length < 3) {
                RN.responsiveTable.convertTable(table);
                return;
            }
            convertTable($(this), i);
        });
    }


// --------------------------------------------------------------------------
// Convert Table
// --------------------------------------------------------------------------
    function convertTable(container, tableCount) {
        var originalTable = container.find('table'),
            columns = originalTable.find('tr:first-child'),
            products = columns.find('td'),
            select = $('<select/>'),
            wrapper = $('<div class="adaptive-table__mobile-view"/>');

        originalTable.addClass('adaptive-table__desktop-view');

        products.each(function(i) {
            var product = $(this);
            select.append(buildOption(i, product, tableCount));
            wrapper.append(buildTable(i, originalTable, product, tableCount));
        });

        wrapper.prepend(select).appendTo(container);

        if(!Modernizr.touchevents) {
            select.chosen({
                disable_search: true
            });
        }

        select.on('change', changeTable);
    }


// --------------------------------------------------------------------------
// Build Select Option
// --------------------------------------------------------------------------
    function buildOption(i, product, tableCount) {
        var isTitleColumn = product.is(':first-child');
        if(!isTitleColumn) {
            var option = $('<option />').attr('value', 'table-' + tableCount + '-' + i).text(product.text())
            return option;
        }
    }


// --------------------------------------------------------------------------
// Build Tables
// --------------------------------------------------------------------------
    function buildTable(i, originalTable, product, tableCount) {
        var isTitleColumn = product.is(':first-child'),
            rows = originalTable.find('tr');

        if(!isTitleColumn) {
            var newTable = $('<table id="table-' + tableCount + '-' + i + '" class="adaptive-table__table"/>');
            rows.each(function() {
                var row = $(this),
                    newRow = $('<tr/>'),
                    isTitleRow = row.is(':first-child');

                if(!isTitleRow) {
                    var title = row.find('td:first-child').text(),
                        description = row.find('td:nth-child(' + (i + 1) + ')').text(),
                        content = '<td>' + title + '</td><td>' + description + '</td>';
                    newRow.append(content);
                    newTable.append(newRow);
                }
            });

            if(i != 1) {
                newTable.hide();
            }

            return newTable;
        }
    }


// --------------------------------------------------------------------------
// Change Table
// --------------------------------------------------------------------------
    function changeTable() {
        var value = $(this).val(),
            target = $('#' + value);

        target.show().siblings('table').hide();
    }


// --------------------------------------------------------------------------
// Return public methods
// --------------------------------------------------------------------------

    return {
        init: init
    };

}();