// --------------------------------------------------------------------------
// tweets.js
// --------------------------------------------------------------------------
// This file adds initialises tweet widgets
// --------------------------------------------------------------------------

RN.tweets = function() {

  'use strict';

// --------------------------------------------------------------------------
// Set up event listeners
// --------------------------------------------------------------------------
  function init() {
    setTimeout(function() {
        byBehaviour('tweet').each(initTweet);
    }, 25);
  }


// --------------------------------------------------------------------------
// Initialise tweet
// --------------------------------------------------------------------------
  function initTweet() {
    var tweet = $(this),
        tweetId = String(tweet.data('tweet-id'));
    tweet.empty();

    twttr.widgets.createTweet(tweetId, tweet[0], {
        theme: 'light',
        conversation: 'none',
        cards: 'hidden'
    });
  }


// --------------------------------------------------------------------------
// Return public methods
// --------------------------------------------------------------------------

  return {
    init: init,
    initTweet: initTweet
  };

}();