// --------------------------------------------------------------------------
// toggleNavigation.js
// --------------------------------------------------------------------------
// This file adds functionality for toggling the mobile nav
// --------------------------------------------------------------------------

RN.toggleNavigation = function() {

  'use strict';

// --------------------------------------------------------------------------
// Set up event listeners
// --------------------------------------------------------------------------
  function init() {
    byBehaviour('toggle-navigation').on('click', toggleNavigation);
    byBehaviour('toggle-subnav').on('click', toggleSubnav);
    byBehaviour('toggle-category-navigation').on('click', toggleCategoryNavigation);
  }

// --------------------------------------------------------------------------
// Toggle the navigation
// --------------------------------------------------------------------------
  function toggleNavigation() {  
    $('html').toggleClass('navigation-is-open').removeClass('sub-navigation-is-open');
    $('.sub-navigation').removeClass('is-open');
    return false;
  }


// --------------------------------------------------------------------------
// Toggle sub navigation
// --------------------------------------------------------------------------
  function toggleSubnav() {  
    var trigger = $(this),
        target = $(trigger.attr('href'));

    target.toggleClass('is-open');
    $('html').toggleClass('sub-navigation-is-open');
    return false;
  }


// --------------------------------------------------------------------------
// Toggle category navigation
// --------------------------------------------------------------------------
  function toggleCategoryNavigation() {  
    byElement('category-navigation').slideToggle();
  }


// --------------------------------------------------------------------------
// Return public methods
// --------------------------------------------------------------------------

  return {
    init: init,
    category: toggleCategoryNavigation
  };

}();