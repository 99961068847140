// --------------------------------------------------------------------------
// accordionTable.js
// --------------------------------------------------------------------------
// This file adds functionality to convert tables into accordions at mobile
// --------------------------------------------------------------------------

RN.accordionTable = function() {

    'use strict';

// --------------------------------------------------------------------------
// Initialisation
// --------------------------------------------------------------------------
    function init() {
        byBehaviour('toggle-accordion-table-description').on('click', toggleDescription);
    }


// --------------------------------------------------------------------------
// Convert Table
// --------------------------------------------------------------------------
    function toggleDescription() {
        var trigger = $(this),
            target = trigger.next();

        if(!trigger.hasClass('accordion-table__title--is-open')) {
            byBehaviour('toggle-accordion-table-description').removeClass('accordion-table__title--is-open');
            trigger.addClass('accordion-table__title--is-open');
            
            byElement('accordion-table-description').hide();
            target.show();
        } else {
            trigger.removeClass('accordion-table__title--is-open');
            target.hide();
        }
        
    }


// --------------------------------------------------------------------------
// Return public methods
// --------------------------------------------------------------------------

    return {
        init: init
    };

}();