// --------------------------------------------------------------------------
// accordionTable.js
// --------------------------------------------------------------------------
// This file adds functionality to convert tables into accordions at mobile
// --------------------------------------------------------------------------

RN.responsiveTable = function() {

    'use strict';

// --------------------------------------------------------------------------
// Initialisation
// --------------------------------------------------------------------------
    function init() {
        $('.wysiwyg table').each(convertTable);
    }


// --------------------------------------------------------------------------
// Convert Table
// --------------------------------------------------------------------------
    function convertTable(table) {
    	
        var table = table ? $(table) : $(this),
            titles = [],
            rows = table.find('tr:not(:first-child)');

        if(!table.length) {
        	return;
        }
        
        table.addClass('responsive-table');

        table.find('tr:first-child td').each(function() {
            titles.push($(this).text());
        });


        rows.each(function() {
            var row = $(this),
                tds = row.find('td');

            tds.each(function(index) {
                $(this).attr('data-title', titles[index]);
                $(this).wrapInner('<span>');
            });
        });
    }


// --------------------------------------------------------------------------
// Return public methods
// --------------------------------------------------------------------------

    return {
        init: init,
        convertTable: convertTable
    };

}();