// --------------------------------------------------------------------------
// siteSearch.js
// --------------------------------------------------------------------------
// This file adds functionality for the expanding search box in the header
// --------------------------------------------------------------------------

RN.siteSearch = function() {

  'use strict';

// --------------------------------------------------------------------------
// Set up event listeners
// --------------------------------------------------------------------------
  function init() {
    byBehaviour('open-search').on('click', openSearch);
    byBehaviour('close-search').on('click blur', closeSearch);
    byElement('search-input').on('focus', openSearch);
  }


// --------------------------------------------------------------------------
// Open the search
// --------------------------------------------------------------------------
  function openSearch() {
    var input = byElement('search-input');
    $('html').addClass('site-search-is-open');
    if(!input.is(':focus')) {
        input.focus();  
    }
  }

// --------------------------------------------------------------------------
// Close the search input
// --------------------------------------------------------------------------
  function closeSearch() {
    $('html').removeClass('site-search-is-open');
  }


// --------------------------------------------------------------------------
// Return public methods
// --------------------------------------------------------------------------

  return {
    init: init
  };

}();