// --------------------------------------------------------------------------
// toggleFilters.js
// --------------------------------------------------------------------------
// This file adds functionality for toggling research filters at mobile
// --------------------------------------------------------------------------

RN.toggleFilters = function() {

  'use strict';

// --------------------------------------------------------------------------
// Set up event listeners
// --------------------------------------------------------------------------
  function init() {
    byBehaviour('toggle-filters').on('click', toggleFilters);
  }


// --------------------------------------------------------------------------
// Toggle Filters
// --------------------------------------------------------------------------
  function toggleFilters() {
    byElement('filters').slideToggle();
  }


// --------------------------------------------------------------------------
// Return public methods
// --------------------------------------------------------------------------

  return {
    init: init
  };

}();