// --------------------------------------------------------------------------
// sliders.js
// --------------------------------------------------------------------------
// This file initializes slick sliders
// --------------------------------------------------------------------------

RN.sliders = function() {

    'use strict';

    var baseSettings = {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
        focusOnSelect: false,
        cssEase: 'cubic-bezier(0.860,  0.000, 0.070, 1.000)',
        speed: 300,
        adaptiveHeight: true
    }

  var defaultSettings = $.extend({}, baseSettings);
  defaultSettings.responsive = [
        {
            breakpoint: 1280,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ];

    var documentSettings = $.extend({}, baseSettings);
    documentSettings.responsive = [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ];
    documentSettings.slidesToShow = 2;
    documentSettings.slidesToScroll = 2;

    var accessorySettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        focusOnSelect: false,
        cssEase: 'cubic-bezier(0.860,  0.000, 0.070, 1.000)',
        speed: 300,
        dots: true,
        adaptiveHeight: true
    }


    // --------------------------------------------------------------------------
    // Initialize slick instances
    // --------------------------------------------------------------------------
    function init() {
        rowSlider();
        infoSlider();
    }


    // --------------------------------------------------------------------------
    // Row Slider w/ visible overflow
    // --------------------------------------------------------------------------
    function rowSlider() {
        byBehaviour('row-slider').each(function() {
            var slider = $(this),
                sliderType = slider.data('slider'),
                settings;

            if(sliderType === 'document') {
                settings = documentSettings;
            } else if(sliderType === 'accessory') {
                settings = accessorySettings;
            } else {
                settings = defaultSettings;
            }
                

            slider.slick(settings);

            if(sliderType === 'tweets') {
                slider.on('breakpoint', function() {
                    slider.slick('unslick');
                    byBehaviour('tweet').each(RN.tweets.initTweet);
                    slider.slick(settings);
                });
            }
            
        });
    }


    // --------------------------------------------------------------------------
    // Info Slider
    // --------------------------------------------------------------------------
    function infoSlider() {
        byBehaviour('info-slider-nav').slick({
            centerMode: true,
            centerPadding: '8%',
            slidesToShow: 3,
            speed: 300,
            asNavFor: "#product-features",
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        centerPadding: "0px"
                    }
                }
            ]
        });

        byBehaviour('info-slider').slick({
            slidesToShow: 1,
            arrows: false,
            speed: 300,
            asNavFor: "#product-features-nav",
        });
    }


    // --------------------------------------------------------------------------
    // Return public methods
    // --------------------------------------------------------------------------

    return {
        init: init
    };

}();