// --------------------------------------------------------------------------
// modal.js
// --------------------------------------------------------------------------
// This file adds functionality for basic modals
// --------------------------------------------------------------------------

RN.modal = function() {

  'use strict';

// --------------------------------------------------------------------------
// Set up event listeners
// --------------------------------------------------------------------------
  function init() {
    byBehaviour('toggle-modal').on('click', toggleModal);
  }


// --------------------------------------------------------------------------
// Toggle Modal
// --------------------------------------------------------------------------
  function toggleModal() {
    var trigger = $(this),
        target = $(trigger.attr('href'));

    $('html').toggleClass('modal-is-open');
    target.toggleClass('modal--is-open');
    $('select').trigger('chosen:updated');
    return false;
  }


// --------------------------------------------------------------------------
// Return public methods
// --------------------------------------------------------------------------

  return {
    init: init
  };

}();