// --------------------------------------------------------------------------
// backToTop.js
// --------------------------------------------------------------------------
// This file adds functionality for scrolling back to top
// --------------------------------------------------------------------------

RN.backToTop = function() {

  'use strict';

// --------------------------------------------------------------------------
// Set up event listeners
// --------------------------------------------------------------------------
  function init() {
    byBehaviour('back-to-top').on('click', backToTop);
    byBehaviour('scroll-to').on('click', scrollTo);
  }


// --------------------------------------------------------------------------
// Scroll back to top
// --------------------------------------------------------------------------
  function backToTop() {
    $('html, body').animate({ scrollTop: 0 });
    return false;
  }

// --------------------------------------------------------------------------
// Scroll to
// --------------------------------------------------------------------------
  function scrollTo() {
    var trigger = $(this),
        target = $(trigger.attr('href')),
        offset = target.offset().top;
    $('html, body').animate({ scrollTop: offset });
    return false;
  }


// --------------------------------------------------------------------------
// Return public methods
// --------------------------------------------------------------------------

  return {
    init: init
  };

}();