// --------------------------------------------------------------------------
// sectionNavigation.js
// --------------------------------------------------------------------------
// This file adds functionality for section navigation
// --------------------------------------------------------------------------

RN.sectionNavigation = function() {

    'use strict';
    var waypoints = [],
        throttle = false;

// --------------------------------------------------------------------------
// Set up event listeners
// --------------------------------------------------------------------------
  function init() {
    byBehaviour('section-navigation').on('click', navigateToSection);
    byBehaviour('section-navigation').each(setupWaypoint);
    $(window).on('scroll', checkWaypoints);
  }


// --------------------------------------------------------------------------
// Navigate to section
// --------------------------------------------------------------------------
  function navigateToSection() {
    var trigger = $(this),
        target = $(trigger.attr('href')),
        text = trigger.text(),
        offset = target.offset().top - 100;

    trigger.blur();
    byBehaviour('toggle-category-navigation').text(text);

    $('html, body').animate({ scrollTop: offset }, 500);

    if($(window).width() < 1024) {
        RN.toggleNavigation.category();
    }

    return false;
  }


// --------------------------------------------------------------------------
// Setup Waypoint
// --------------------------------------------------------------------------
  function setupWaypoint() {
    var trigger = $(this),
        target = $(trigger.attr('href'));

    waypoints.push([trigger, target]);
  }


// --------------------------------------------------------------------------
// Check Waypoints
// --------------------------------------------------------------------------
  function checkWaypoints() {
    if(throttle === false) {
        var scroll = $(window).scrollTop() + $('.category-navigation').height();
        for(var i = 0; i < waypoints.length; i ++) {
            var waypoint = waypoints[i][1],
                link = waypoints[i][0];

            if(scroll >= waypoint.offset().top) {
                $('.category-navigation__link--is-active').removeClass('category-navigation__link--is-active');
                link.addClass('category-navigation__link--is-active');
            }
        }
        throttle = true;
        setTimeout(function() {
            throttle = false;
        }, 100);
    }
  }


// --------------------------------------------------------------------------
// Return public methods
// --------------------------------------------------------------------------

  return {
    init: init
  };

}();