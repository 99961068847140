// --------------------------------------------------------------------------
// languageSelect.js
// --------------------------------------------------------------------------
// This file adds functionality to the language select drop down
// --------------------------------------------------------------------------

RN.languageSelect = function() {

  'use strict';

// --------------------------------------------------------------------------
// Set up event listeners
// --------------------------------------------------------------------------
  function init() {
    byBehaviour('toggle-language-select').on('click', toggleLanguageSelect);
    byElement('language-list').find('li:last-child a').on('blur', toggleLanguageSelect);
    $(document).on('click', closeLanguageSelect);
  }

// --------------------------------------------------------------------------
// Toggle the language Select
// --------------------------------------------------------------------------
  function toggleLanguageSelect(e) {
    e.stopPropagation();
    var trigger = $(this);
    byElement('language-list').toggleClass('is-open');
    return false;
  }

// --------------------------------------------------------------------------
// Close the language Select
// --------------------------------------------------------------------------
  function closeLanguageSelect() {
    byElement('language-list').removeClass('is-open');
  }


// --------------------------------------------------------------------------
// Return public methods
// --------------------------------------------------------------------------

  return {
    init: init
  };

}();