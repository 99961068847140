// --------------------------------------------------------------------------
// password.js
// --------------------------------------------------------------------------
// This file adds functionality hiding / showing password field
// --------------------------------------------------------------------------

RN.password = function() {

  'use strict';

// --------------------------------------------------------------------------
// Set up event listeners
// --------------------------------------------------------------------------
  function init() {
    byBehaviour('toggle-password').on('click', togglePassword);
  }


// --------------------------------------------------------------------------
// Toggle password view
// --------------------------------------------------------------------------
  function togglePassword() {
    console.log('pass')
    var trigger = $(this),
        password = byElement('password-field');

    if(password.attr('type') === 'password') {
        password.attr('type', 'text');
        trigger.text(trigger.data('hide-text'));
    } else {
        password.attr('type', 'password');
        trigger.text(trigger.data('show-text'));
    }

    password.focus();
  }


// --------------------------------------------------------------------------
// Return public methods
// --------------------------------------------------------------------------

  return {
    init: init
  };

}();