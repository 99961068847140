// --------------------------------------------------------------------------
// imageCaptions.js
// --------------------------------------------------------------------------
// This file adds captions to images
// --------------------------------------------------------------------------

RN.imageCaptions = function() {

    'use strict';

// --------------------------------------------------------------------------
// Initialisation
// --------------------------------------------------------------------------
    function init() {
        var images = $('p[data-redactor-inserted-image="true"] img[alt^="caption:"]'), 
        	caption;
        
        images.each(function(index) {
        	caption = $(this).attr("alt");
        	caption = caption.replace("caption:", "").trim();
        	$(this).parent().append("<span class='captioned-text'>" + caption + "</span>");
        	$(this).parent().wrapInner("<span class='captioned'></span>");
        });        
    }

// --------------------------------------------------------------------------
// Return public methods
// --------------------------------------------------------------------------

    return {
        init: init
    };

}();