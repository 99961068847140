// --------------------------------------------------------------------------
// selects.js
// --------------------------------------------------------------------------
// This file adds functionality for chosen custom selects
// --------------------------------------------------------------------------

RN.selects = function() {

  'use strict';

// --------------------------------------------------------------------------
// Set up event listeners
// --------------------------------------------------------------------------
  function init() {
    if(!Modernizr.touchevents) {
        byElement('basic-select').chosen({
            disable_search: true
        });
    }
  }


// --------------------------------------------------------------------------
// Return public methods
// --------------------------------------------------------------------------

  return {
    init: init
  };

}();